import { AxiosInstance } from 'axios';
import { useState, useEffect } from 'react';

const useEasyHttpClient = (httpClient: AxiosInstance) => {
  const [error, setError] = useState<string | undefined | null>(null);

  const response = httpClient.interceptors.response;
  const resInterceptor = response.use(
    res => res,
    err => {
      const message = err?.response?.data?.error?.message;
      if (message) {
        setError(message)
      } else {
        setError(err.message);
      }
      return Promise.reject(err);
    }
  );

  useEffect(() => {
    return () => {
      response.eject(resInterceptor);
    };
  }, [resInterceptor, response]);

  const errorConfirmedHandler = () => {
    setError(null);
  };

  return { error: error, errorConfirmedHandler: errorConfirmedHandler };
}

export default useEasyHttpClient;
