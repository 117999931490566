import { IButtonStyles, IModalStyles, IProgressIndicatorStyles, ISpinnerStyles, } from "@fluentui/react";

interface ILoadingModalStyles {
  modal: IModalStyles,
  spinner: ISpinnerStyles,
  progress: IProgressIndicatorStyles,
  closeButton: IButtonStyles,
}
export const getStyles: () => ILoadingModalStyles = () => {

  return {
    modal: {
      root: {
      },
      main: {},
      keyboardMoveIcon: {},
      keyboardMoveIconContainer: {},
      layer: {},
      scrollableContent: {},
    },
    spinner: {
      label: {
        fontWeight: 500,
      }
    },
    progress: {
      root: {},
      itemDescription: {},
      itemName: {},
      itemProgress: {},
      progressBar: {},
      progressTrack: {},
    },
    closeButton: {
      root: {
        //color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
      },
      rootHovered: {
        //color: theme.palette.neutralDark,
      },
    },
    // spinner: {
    //   root: {

    //     zIndex: 9100,
    //     position: "fixed",
    //     left: "50%",
    //     bottom: "50%", // L'ho messo in riferimento al BOTTOM altrimenti su mobile sfarfalla quando scompare la barra in alto del browser mentre si scorre col dito.
    //     transform: "translateX(-50%) translateY(50%)",
    //     width: "25em",
    //     height: "12em",
    //     maxHeight: "90vh",
    //     maxWidth: "90vw",
    //     backgroundColor: theme.palette.white,
    //     borderColor: theme.palette.themePrimary,
    //     borderStyle: "solid",
    //     borderWidth: 1,
    //     boxShadow: "0px 0px 10px 0px " + theme.palette.themePrimary,
    //   }, label: {
    //     fontWeight: 500,

    //   }
    // },
  }
}

export default getStyles;
