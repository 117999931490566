import {
  PublicClientApplication,
  // AuthorizationUrlRequest,
  SilentRequest,
  Configuration,
  // AuthenticationResult // debug
} from "@azure/msal-browser";

let msalApp: PublicClientApplication;
export const getMsalApp = () => {
  return msalApp;
}
export const setMsalApp = (config: Configuration) => {
  msalApp = new PublicClientApplication(config)
}

// Nota: non è supportato richiedere scopes di resource diversi
// Esempio non puoi richiedere un token per lo scope delle API di Easy
// insieme a scope di Graph -> https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/774973ba03408e628b8831f95477ca1665d43d02/lib/msal-browser/docs/resources-and-scopes.md
export const acquireToken = (scopes: string[]) => {
  // per simulare una richiesta di interazione
  //return new Promise<AuthenticationResult>((resolve, reject) => { reject({ errorCode: "interaction_required" }) })
  const silentRequest: SilentRequest = {
    account: msalApp.getAllAccounts()[0],
    scopes: scopes
  }
  return msalApp.acquireTokenSilent(silentRequest)
};

export const acquireTokenPopup = (scopes: string[]) => {
  // per simulare una richiesta di interazione
  //return new Promise<AuthenticationResult>((resolve, reject) => { reject({ errorCode: "interaction_required" }) })
  const silentRequest: SilentRequest = {
    account: msalApp.getAllAccounts()[0],
    scopes: scopes
  }
  return msalApp.acquireTokenPopup(silentRequest)
};
