import { IColumn } from '@fluentui/react';

export const columns: IColumn[] = [{
  key: "username",
  minWidth: 100,
  maxWidth: 150,
  name: "Username",
  fieldName: "username",
  isResizable: true
}, {
  key: "name",
  minWidth: 100,
  maxWidth: 150,
  name: "Nome",
  fieldName: "name",
  isResizable: true
}, {
  key: "email",
  minWidth: 100,
  maxWidth: 150,
  name: "Email",
  fieldName: "email",
  isResizable: true
}];
