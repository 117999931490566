import React from 'react';
import easyClient from '../../shared/easyAxios';
import withHttpError from '../HttpClient/EasyHttpClient';
import withInteractiveLogin, { IWithInteractiveLoginProps } from '../InteractiveLoginWrapper/InteractiveLoginWrapper';

const ApiWrapper: <P extends IWithInteractiveLoginProps>(WrappedComponent: React.FC<P>) => React.FC<Omit<P, keyof IWithInteractiveLoginProps>> = (WrappedComponent) =>
  withInteractiveLogin(
    withHttpError(WrappedComponent, easyClient.getInstance())
  );

export default ApiWrapper;
