import React, { useEffect, useRef, useState } from 'react';
import { IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, Shimmer, ShimmerElementType } from '@fluentui/react';
import { IODataList } from '../../models/odata';
import { IHttpClient } from '../../shared/easyAxios';

const suggestionProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: 'Nessun utente trovato',
};

export interface IUser {
  id: string;
  name: string;
  email: string;
}

interface IUserPickerProps {
  easyClient: IHttpClient;
  limitUser?: number;
  value: IPersonaProps[];
  valueChange: (selectedUsers: IPersonaProps[]) => void;
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function toPickerPerson(person: IUser): IPersonaProps {
  return {
    key: person.id,
    id: person.id,
    text: person.name,
    secondaryText: person.email
  } as IPersonaProps;
}

const shimmerElementType = [
  { type: ShimmerElementType.circle },
  { type: ShimmerElementType.gap, width: '2%' },
  { type: ShimmerElementType.line },
];

const UserPicker: React.FC<IUserPickerProps> = (props) => {

  const easyClient = props.easyClient;
  const selectedUsers = props.value;
  const selectedUserChangeHandler = props.valueChange;
  const [loading, setLoading] = useState(false);
  const userAlreadyDecoded = useRef<string[]>([]);

  useEffect(() => {
    const usersNoText = selectedUsers.filter(u => u.id && !u.text && !userAlreadyDecoded.current.includes(u.id)).slice(0, 50);
    if (usersNoText.length) {
      setLoading(true);
      const ids = usersNoText.map(u => "'" + u.id + "'");
      let apiUrl = "/api/Users?$select=id,name&$filter=id in (" + ids.join(",") + ")";
      easyClient.get<IODataList<{ id: string, name: string }>>(apiUrl, { headers: { "X-version": "1.0" } })
        .then(response => {
          userAlreadyDecoded.current.push(...usersNoText.map(u => u.id ?? ""));
          const selectedUsersById = selectedUsers.reduce<Record<string, IPersonaProps>>((agg, cur) => {
            if (!cur.id)
              return agg;
            agg[cur.id] = agg[cur.id] ?? cur;
            return agg;
          }, {});
          response.data.value.forEach(u => {
            selectedUsersById[u.id].text = u.name;
          })
        }).finally(() => setLoading(false));
    }
  }, [selectedUsers, easyClient]);

  const resolveSuggestionsHandler = (filter: string): PromiseLike<IPersonaProps[]> => {
    let apiUrl = "/api/Users?$top=100&$orderby=Name";
    if (filter) {
      apiUrl = apiUrl + "&search=" + encodeURIComponent(filter)
    }
    if (selectedUsers.length) {
      const ids = selectedUsers.map(p => `'${p.id}'`);
      apiUrl = apiUrl + `&$filter=not(id in (${ids.join(",")}))`;
    }
    return easyClient.get<IODataList<IUser>>(apiUrl, { headers: { "X-version": "1.0" } }).then(resp => {
      return resp.data.value.map<IPersonaProps>(toPickerPerson);
    });
  }

  const emptyResolveSuggestionsHandler = () => {
    return resolveSuggestionsHandler("");
  }

  const removePersonHandler = (item: IPersonaProps): void => {
    const indexPeopleList: number = selectedUsers.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = selectedUsers
        .slice(0, indexPeopleList)
        .concat(selectedUsers.slice(indexPeopleList + 1));
      selectedUserChangeHandler(newPeople)
    }
  };

  const pickerChangedHandler = (items?: IPersonaProps[] | undefined) => {
    if (!items)
      return;
    selectedUserChangeHandler(items);
  }

  return (<div>
    {loading ?
      <>
        <Shimmer shimmerElements={shimmerElementType} />
        <Shimmer shimmerElements={shimmerElementType} />
        <Shimmer shimmerElements={shimmerElementType} />
      </> :
      <NormalPeoplePicker
        onResolveSuggestions={resolveSuggestionsHandler}
        onEmptyInputFocus={emptyResolveSuggestionsHandler}
        onEmptyResolveSuggestions={emptyResolveSuggestionsHandler}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={'ms-PeoplePicker'}
        onRemoveSuggestion={removePersonHandler}
        onChange={pickerChangedHandler}
        resolveDelay={300}
        selectedItems={selectedUsers} />}
  </div>)
}

export default UserPicker;
