import React from 'react';
import { Stack, Text, Icon } from '@fluentui/react';

export interface NoDataProps {
  title?: string;
  iconName?: string;
}

const NoData: React.FC<NoDataProps> = (props) => {
  const title = props.title ?? "Nessun elemento"
  const iconName = props.iconName ?? "SearchIssue";
  return (
    <Stack horizontalAlign="center" >
      <Text>{title}</Text>
      <Icon iconName={iconName} styles={{ root: { fontSize: "5rem", lineHeight: "8rem" } }} />
    </Stack>
  );
}

export default NoData;
