export interface IPaginationInfo {
  currPage: number;
  pageSize: number;
  orderby: string;
}

export function toODataParam(state: IPaginationInfo, search?: string): string {
  var qs: any = { "$count": "true" };
  if (state.pageSize > 0) {
    qs["$top"] = state.pageSize;
  }
  if (state.currPage > 1) {
    qs["$skip"] = (state.currPage - 1) * state.pageSize;
  }
  if (state.orderby) {
    qs["$orderby"] = state.orderby;
  }
  if (search) {
    qs["search"] = search;
  }
  return Object.keys(qs)
    .map(key => key + "=" + encodeURIComponent(qs[key]))
    .join("&");
}
