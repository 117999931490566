import React from 'react'
import { IColumn } from '@fluentui/react';
import { ReactNode } from 'react';
import { IDocumentGroup } from './DocumentGroup.types';

export const columns: IColumn[] = [{
  key: "id",
  minWidth: 100,
  maxWidth: 150,
  name: "Codice",
  fieldName: "id",
  isResizable: true
}, {
  key: "name",
  minWidth: 100,
  maxWidth: 150,
  name: "Nome",
  fieldName: "description",
  isResizable: true
}, {
  key: "publishableDocTypeIds",
  minWidth: 100,
  maxWidth: 150,
  name: "Doc.trasmissione",
  isResizable: true,
  fieldName: "publishableDocTypeIds"
}, {
  key: "receivableDocTypeIds",
  minWidth: 100,
  maxWidth: 150,
  name: "Doc.ricezione",
  isResizable: true,
  fieldName: "receivableDocTypeIds"
}]

export const renderColumnHandler = (item?: IDocumentGroup, index?: number | undefined, column?: IColumn | undefined): ReactNode => {
  if (!item || !column)
    return;
  const fieldContent = item[column.fieldName as keyof IDocumentGroup];

  switch (column.key) {
    case 'publishableDocTypeIds':
    case "receivableDocTypeIds":
      if (typeof Array === typeof fieldContent)
        return <span>{(fieldContent as string[]).join(", ")}</span>
      break;
  }
  return <span>{fieldContent?.toString()} </span>;

}
