import React from 'react';
import { ContextualMenu, Dialog, DialogType, IModalProps, PrimaryButton } from '@fluentui/react'

const modalProps: IModalProps = {
  isBlocking: true,
  dragOptions: {
    moveMenuItemText: "Sposta",
    closeMenuItemText: "Chiudi",
    menu: ContextualMenu,
    keepInBounds: true,
  }
}

interface IAuthorizazionDialogProps {
  authorize: () => void;
  cancel: () => void;
}

const AuthorizationDialog: React.FC<IAuthorizazionDialogProps> = (props) => {
  return (
    <Dialog
      hidden={false}
      modalProps={modalProps}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Autorizzazione",
        subText: "Per proseguire occorre confermare le proprie credenziali",
        onDismiss: props.cancel
      }}
    >
      <PrimaryButton onClick={props.authorize} text="Ok" />
    </Dialog>)
}

export default AuthorizationDialog;
