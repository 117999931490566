import { ContextualMenu, Dialog, DialogType, IModalProps, PrimaryButton } from '@fluentui/react'
import React from 'react';
import { AxiosInstance } from 'axios';
import useEasyHttpClient from '../../hooks/useEasyHttpClient';

const mdoalProps: IModalProps = {
  isBlocking: true,
  dragOptions: {
    moveMenuItemText: "Sposta",
    closeMenuItemText: "Chiudi",
    menu: ContextualMenu,
    keepInBounds: true,
  }
}

const EasyHttpClient = <T,>(WrappedComponent: React.FC<T>, axios: AxiosInstance): React.FC<T> => {

  const Hof: React.FC = (props) => {
    const { error, errorConfirmedHandler } = useEasyHttpClient(axios);
    return <>
      {error && error.length > 0 && (
        <Dialog
          hidden={false}
          modalProps={mdoalProps}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Errore",
            subText: error || "",
            onDismiss: errorConfirmedHandler
          }}>
          <PrimaryButton onClick={errorConfirmedHandler} text="Ok" />
        </Dialog>
      )}
      <WrappedComponent {...props as T} />
    </>
  }
  return Hof;
}
export default EasyHttpClient;
