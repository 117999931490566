import React from 'react';
import { Icon } from '@fluentui/react';
import styleFactory from './LinkList.style';
import Title2 from '../UI/Title2';

const LinkList: React.FC = () => {
    const style = styleFactory();
    return (
        <section className={style.LinkListSection}>
            <Title2>Link Utili</Title2>
            <ul className={style.LinkList}>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="DeliveryTruck" /><p>Conferimenti</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="ReportDocument" /><p>Report</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="Savings" /><p>Risparmi</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="RainShowersDay" /><p>Meteo</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="Rocket" /><p>Rapide</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="Megaphone" /><p>News</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="Tag" /><p>Etichette</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="Globe" /><p>La rete</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="LightningBolt" /><p>Imprevisti</p></li>
                <li className={style.LinkItem}><Icon className={style.Icon} iconName="Trophy" /><p>Premi</p></li>
            </ul>
        </section>
    );

}

export default LinkList;